import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'

export default function useIsMobileLayout() {
  const { query: routerQuery } = useRouter()
  const layoutRef = useRef(false)

  // Save the cwLayout parameter
  useEffect(() => {
    if (routerQuery.cwLayout && !layoutRef.current) {
      document.cookie = `x-carewell-layout=${routerQuery.cwLayout}; path=/`
      layoutRef.current = true
    }
  }, [routerQuery.cwLayout])

  const cookieQuery = useMemo(() => {
    if (typeof window !== 'undefined') {
      const cookie = document.cookie.match(/x-carewell-layout=*([^;]+)/gi) || []
      return cookie?.[0]?.split('=')?.[1]
    }

    return null
  }, [])

  return (routerQuery.cwLayout || cookieQuery) === 'mobile'
}
