import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'
import useNostoRecommendations from './useNostoRecommendations'

export default function useNostoBuilderPage(isHomepage) {
  const { setPageType, loadRecs } = useNostoContext()
  useEffect(() => {
    //Clear previous recommendations and then setup the page
    loadRecs(null)
    if (isHomepage) {
      setPageType('FRONT') // If this is the homepage we set  the page type to front to pass to Nosto
    }
  }, [isHomepage, setPageType, loadRecs])
  useNostoRecommendations()
}
