import { createContext, useContext } from 'react'
import type { MainGetStaticPropsReturn } from '../../../../lib/serverOnly/pageTypes/global/mainLayout/mainLayout_getStaticProps'

const MainLayoutContext = createContext<MainGetStaticPropsReturn>(null as any)

export function useMainLayoutStaticProps() {
  const res = useContext(MainLayoutContext)
  // eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
  if (!res) {
    throw new Error('Attempt to access MainLayoutContext has failed due to no provider in tree')
  }
  return res
}

export default MainLayoutContext
