import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'
import useNostoCartTrack from './useNostoCartTrack'
import useNostoLoginTrack from './useNostoLoginTrack'

export default function useNostoRecommendations() {
  const {
    nostoLoaded,
    loadRecs,
    recommendationsLoaded,
    pageType,
    productID,
    category,
    searchTerm,
    recommendationsLoading,
    loadingNostoRecommendations,
  } = useNostoContext()
  useNostoLoginTrack()
  useNostoCartTrack()

  useEffect(() => {
    if (nostoLoaded && window.nostojs && !recommendationsLoaded && !recommendationsLoading) {
      window.nostojs((api) => {
        loadingNostoRecommendations(true)
        const session = api.defaultSession().setResponseMode('JSON_ORIGINAL')

        const viewMethod =
          {
            CATEGORY: () => session.viewCategory(category),
            '404': session.viewNotFound,
            CART: session.viewCart,
            SEARCH: () => session.viewSearch(searchTerm),
            FRONT: session.viewFrontPage,
            PRODUCT: () => session.viewProduct(productID),
          }[pageType || ''] || session.viewOther

        viewMethod()
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((response) => {
            const recs = response.recommendations ?? null
            loadRecs(recs)
            loadingNostoRecommendations(false)
          })
      })
    }
  }, [
    nostoLoaded,
    loadRecs,
    pageType,
    productID,
    category,
    searchTerm,
    recommendationsLoaded,
    recommendationsLoading,
    loadingNostoRecommendations,
  ])
}
