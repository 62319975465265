import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'
import useCartQuery from '../../../hooks/useCartQuery'

export default function useNostoCartTrack() {
  const { nostoLoaded } = useNostoContext()
  const { data } = useCartQuery()

  const cartItems = data?.items.map((item) => {
    return {
      name: item.productName,
      price_currency_code: 'USD',
      product_id: item.productId.toString(),
      quantity: item.quantity,
      unit_price: item.price || item.listPrice,
    }
  })

  useEffect(() => {
    if (nostoLoaded && window.nostojs && cartItems) {
      window.nostojs((api) => {
        api.defaultSession().setCart({
          items: cartItems,
        })
      })
    }
  }, [nostoLoaded, cartItems])
}
