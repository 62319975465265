import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import useIsMobileLayout from '../../../../hooks/useIsMobileLayout'
import type { MainGetStaticPropsReturn } from '../../../../lib/serverOnly/pageTypes/global/mainLayout/mainLayout_getStaticProps'
import type { MainLayoutBreadcrumb } from './MainLayoutBreadcrumbs'
import MainLayoutContext from './MainLayoutContext'

const MobileLayout = dynamic(() => import('./MobileLayout'))
const ResponsiveLayout = dynamic(() => import('./ResponsiveLayout'))

export type MainLayoutProps = {
  staticProps: MainGetStaticPropsReturn
  children: ReactNode
  crumbs?: MainLayoutBreadcrumb[]
  backgroundColor?: string
  className?: string
  contentClassName?: string
  useBodyPadding?: boolean
  useContainer?: boolean
  subMenu?: ReactNode
  isMobileLayout?: boolean
}

export default function MainLayout({ children, ...props }: MainLayoutProps) {
  const isMobileLayout = useIsMobileLayout()

  const layout =
    props.isMobileLayout || isMobileLayout ? (
      <MobileLayout {...props}>{children}</MobileLayout>
    ) : (
      <ResponsiveLayout {...props}>{children}</ResponsiveLayout>
    )

  return <MainLayoutContext.Provider value={props.staticProps}>{layout}</MainLayoutContext.Provider>
}
