import { useEffect } from 'react'
import { useNostoContext } from '../../../components/global/nosto/NostoContext'
import useCustomerQuery from '../../../hooks/useCustomerQuery'

export default function useNostoLoginTrack() {
  const { nostoLoaded } = useNostoContext()
  const { data: customer } = useCustomerQuery()

  useEffect(() => {
    if (nostoLoaded && window.nostojs && customer?.id) {
      window.nostojs((api) => {
        api.defaultSession().setCustomer({
          customer_reference: customer?.id.toString(),
          email: customer?.email,
          first_name: customer?.firstName,
          last_name: customer?.lastName,
        })
      })
    }
  }, [nostoLoaded, customer])
}
